@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

.App {
  text-align: center;
  overscroll-behavior: none;
  width: 100vw;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "DM Sans";
  src: url("assets/dmsans.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Stardom;
  src: url("assets/stardom.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Host;
  src: url("assets/host.ttf");
  font-display: swap;
  font-style: normal;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.blur {
  width: 100vw;
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(20px);
  background-color: #00000000;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: 80;
  pointer-events: none; /* Ensures no interaction issues */
}
